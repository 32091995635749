function useLiveChat() {
	function showLiveChat() {
		window.showFsLiveChat && window.showFsLiveChat();
	}

	function hideLiveChat() {
		if (window.fsLiveChatMount) {
			window.hideFsLiveChat();
		}
	}

	return {
		showLiveChat,
		hideLiveChat
	};
}

export default useLiveChat;
